import React, { useState, createElement, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import { colors } from '../../assets/colors';
import { CODES } from '../../utils/responseCodes';
import { navigatePages } from '../../utils/function';
import AuthService from '../../services/auth';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AppstoreAddOutlined, AppstoreTwoTone, } from '@ant-design/icons/lib/icons';
import Swal from 'sweetalert2';
import { Global } from '../../utils/general';

//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const settings = [];

const BarHeader = ({ validationInfoChange = true, setValidationInfoChange, userToken, collapsed, setCollapsed, menu, logoClient, pageTitle, setPageTitle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = userToken.user;
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);
    const [categories, setCategories] = useState(null);
    const path = Global.path

    useEffect(() => {
        let tmp = menu?.filter(e => Number(e.ubicacionMenu) === 1);
        let categorias = tmp?.reduce((acc, item) => { //itera cada elemento del tmp
            if (!acc.includes(item.categoria)) { //esta condicion valida que no este antes
                acc.push(item.categoria); //agrega al acc temporal
            }
            return acc;
        }, []);
        //categorias?.sort();
        setCategories(categorias);
    }, [menu])

    const signOut = async () => {
        Swal.fire({
            title: '¿Seguro de Cerrar Sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: 'Si, Cerrar Sesión'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'error',
                    title: 'Cerrando...',
                    timer: 4000

                })
                setTimeout(
                    AuthService.signOut().then(result => {
                        if (result.status === CODES.BAD_TOKEN_498 || result.status === CODES.SUCCESS_200) {
                            localStorage.removeItem("infoAssisProd");
                            navigate(path);
                            window.location.reload();
                        }
                    }), 10000);
            }
        })
    }

    return (
        <AppBar position="static" className='bar-menu'
            sx={{
                border: `solid 0.05rem ${colors.primary.hex}`,

                width: { xs: '97%', md: '99%' },
                margin: '0.5rem',
                borderRadius: '15px',
                background: { xs: colors.primary.hex, md: 'rgb(247, 247, 247, 0.7)', },

            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* inicio logo pantalla grande */}
                    <div className='logo-client'>
                        <img
                            style={{ marginRight: 5 }}
                            height={20}
                            src={logoClient}
                        />
                    </div>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            ml: 1,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.02rem',
                        }}
                        color='primary'
                    >
                        {pageTitle}
                    </Typography>
                    {/* fin logo pantalla grande */}


                    {/* inicio menu pantalla responsive */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* menu al momento de volverse responsive */}
                        <NavDropdown
                            title={<MenuIcon style={{ fontSize: 'xx-large' }} />}
                            id="basic-nav-dropdown"
                        >
                            {categories?.map((category) => {
                                let menuTmp = menu?.filter(e => e.categoria === category && Number(e.ubicacionMenu) === 1);
                                return (
                                    <NavDropdown
                                        key={category}
                                        title={category}
                                        style={{ paddingLeft: 15, paddingTop: 5 }}>
                                        {menuTmp?.map((item) => {
                                            return (
                                                <NavDropdown.Item
                                                    key={item.desPagina}
                                                    onClick={() => navigatePages(location, navigate, setPageTitle, item.desPagina, item.link, validationInfoChange, setValidationInfoChange)}
                                                >{item.desPagina}</NavDropdown.Item>
                                            )
                                        })}
                                    </NavDropdown>
                                )
                            })}
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                style={{ textAlign: 'center' }}
                                onClick={() => setCollapsed(!collapsed)}
                            ><MenuOpenIcon /></NavDropdown.Item>
                        </NavDropdown>
                    </Box>
                    {/* fin menu pantalla responsive */}


                    {/* inicio logo pantalla responsive */}
                    <div className='logo-client-responsive'>
                        <img
                            style={{ marginRight: 8, marginLeft: 8 }}
                            height={12}
                            src={logoClient}
                        />
                    </div>
                    <Typography
                        variant="subtitle1"
                        noWrap
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.02rem',
                            color: 'inherit',
                            textAlign: 'center'
                        }}
                    >
                        {pageTitle}
                    </Typography>
                    {/* fin logo pantalla responsive */}


                    {/* inicio menu pantalla grande */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {categories?.map((category) => {
                            let menuTmp = menu?.filter(e => e.categoria === category && Number(e.ubicacionMenu) === 1);
                            return (
                                <NavDropdown title={category} key={category}
                                    className='menu-bar'
                                >
                                    {menuTmp?.map((item) => {
                                        return (
                                            <NavDropdown.Item
                                                key={item.desPagina}
                                                onClick={() => navigatePages(location, navigate, setPageTitle, item.desPagina, item.link, validationInfoChange, setValidationInfoChange)}
                                            >{item.desPagina}</NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                            )
                        })}
                    </Box>
                    {/* fin menu pantalla grande */}

                    {createElement(collapsed ? AppstoreAddOutlined : AppstoreTwoTone, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                        style: { marginRight: '10px', fontSize: 'x-large', color: colors.primary.hex },
                    })}

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title={user?.trabajador}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={user?.usuario?.toUpperCase()} src="/static/images/avatar/2.jpg" sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        //onClick={signOut}
                        >
                            <MenuItem
                                onClick={() => navigate(path)}
                            >
                                <ListItemIcon>
                                    <HomeIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" color="text.secondary">
                                    Home
                                </Typography>
                            </MenuItem>
                            {settings.map((setting) => {
                                return (
                                    <MenuItem key={setting}
                                        onClick={() => navigate(setting.key)}
                                    >
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                );
                            })}
                            <MenuItem
                                onClick={signOut}>
                                <ListItemIcon>
                                    <PowerSettingsNewIcon fontSize="small" color='error' />
                                </ListItemIcon>
                                <Typography variant="body2" color="text.secondary">
                                    Cerrar Sesión
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default BarHeader;